import { useEffect, useRef } from "react";


const useTitleChanger = (title: string, prevail: boolean = false) => {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => () => {
        if (!prevail) {
            document.title = defaultTitle.current;
        }
    }, []);
}

export default useTitleChanger;