import React from "react";

import styles from "./styles/MaintenanceMessage.module.css";
import maintenance from "./assets/maintenance.gif";
import useTitleChanger from "../../utils/title-changer";

const MaintenanceMessage = () => {
  useTitleChanger('Website under maintenance!');

  return (
    <div>
      <div className={styles.mainContainer}>
        <img src={maintenance} alt="Website under maintenance" />
        Our website is under maintenance right now, please try again later!
      </div>
    </div>
  );
};

export default MaintenanceMessage;
